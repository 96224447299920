@use "sass:map";
@use "fontawesome/scss/fontawesome";
@use "fontawesome/scss/solid";
@use "fontawesome/scss/brands";

@use "reset";
@use "colors" as *;
@use "mixins" as *;
@use "typography";
@use "spacing";
@use "pages";
@use "icons";

body {
  position: relative;
  font-family: typography.$primary-font;
  font-size: typography.$base-font-size;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  position: relative;
  padding-top: spacing.$navbar-height;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

section {
  width: 100%;
  margin: 0 auto;
  max-width: min(spacing.$body-max-width, 100%);
  padding: spacing.$body-margin spacing.$body-margin 2*spacing.$body-margin;
}

section section {
  max-width: none;
  padding: 0;
}

header {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: white;
}

header.shadow {
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: spacing.$body-margin;
  min-height: spacing.$navbar-height;

  &__logo img {
    height: spacing.$navbar-height;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    max-width: 100%;
    padding: 2*spacing.$body-margin;
    box-shadow: -5px 0 5px map.get($colors, grayscale, 200);
    background-color: map.get($colors, grayscale, 100);
    z-index: 999;
    transition: right 0.1s;
    color: map.get($colors, primary, 300);
    
    @include greater-than(mobile) {
      height: auto;
      position: static;
      flex-direction: row;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
    }

    &.opened {
      right: 0;
    }

    a {
      text-decoration: none;
      color: inherit;
      margin: 0 0 spacing.$single;
    }

    & a.active {
      position: relative;
      color: map.get($colors, highlight, 300);

      @include curved-underline(map.get($colors, highlight, 300));
    }

    @include greater-than(mobile) {
      display: flex;

      a + a {
        margin: 0 0 0 spacing.$single;
      }
    }

    &__btn {
      background: none;
      border: none;
      color: map.get($colors, primary, 600);
      z-index: 1000;
    
      @include greater-than(mobile) {
        display: none;
      }
    }
  }

  &__usermenu {
    position: relative;

    @include greater-than(mobile) {
      padding-left: 1rem;
    }

    .user-btn {
      display: none;
      color: inherit;
      background: none;
      border: none;

      cursor: pointer;

      @include greater-than(mobile) {
        display: inline;
      }
    }
    
    ul {
      border-top: 1px solid map-get($colors, grayscale, 200);
      padding: 1rem 0 0;
      list-style: none;

      @include greater-than(mobile) {
        display: none;

        position: absolute;
        top: 100%;
        right: 0;
        padding: 1rem;
        border-top: none;

        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        background-color: #fafafa;

        &.show {
          display: block;
        }
      }
    }

    .user-title {
      color: map-get($colors, grayscale, 500);
    }

    .user-subtitle {
      color: map-get($colors, grayscale, 400);
      font-size: 0.8em;
      margin-bottom: 1rem;
    }

    .btn-link {
      background: none;
      border: none;
      padding: 0;
      color: inherit;
      cursor: pointer;
      margin: 1rem 0 0;
    }
  }
}

.footer {
  color: map.get($colors, grayscale, 100);
  background-color: $gray-primary;
  
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;
    padding: 2*spacing.$body-margin spacing.$body-margin;
    max-width: spacing.$body-max-width;
    margin: 0 auto;

    @include greater-than(mobile) {
      flex-direction: row;
    }

    ul.social {
      display: flex;
      gap: 1rem;
      font-size: 2em;
    }
  }

  a {
    color: inherit;
  }

  ul {
    padding: 0;
    list-style: none;
  }
}

.pagination-nav {
  display: flex;
  justify-content: center;
  
  ul {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;

    color: map.get($colors, grayscale, 200);

    .is-current {
      color: map.get($colors, highlight, 300);
    }

    a {
      text-decoration: none;
      color: map.get($colors, primary, 500);
    }
  }
}